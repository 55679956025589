<template>
  <div v-motion-slide-visible-once-bottom class="bg-white py-24 sm:py-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl text-center">
        <l-h3 class="">From the blog</l-h3>
        <p class="mt-2 text-lg leading-8 text-gray-600">
          Gain valuable insights from compliance experts for understanding and successfully
          navigating the challenging HIPAA landscape.
        </p>
      </div>
      <div
        class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
        <article
          v-for="post in posts"
          :key="post.id"
          class="flex flex-col items-start justify-between">
          <div class="relative w-full">
            <nuxt-img
              provider="cloudinary"
              :src="post.cloudinary"
              width="384px"
              height="246px"
              loading="lazy"
              :alt="post.alt"
              class="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]" />

            <div class="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
          </div>
          <div class="max-w-xl">
            <div class="mt-8 flex items-center gap-x-4 text-xs">
              <time :datetime="post.datetime" class="text-gray-500">{{ post.date }}</time>
              <a
                :href="post.category.href"
                class="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100"
                >{{ post.category.title }}</a
              >
            </div>
            <div class="group relative">
              <h3
                class="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                <a :href="post.href">
                  <span class="absolute inset-0" />
                  {{ post.title }}
                </a>
              </h3>
              <p class="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">
                {{ post.description }}
              </p>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script setup>
const posts = [
  {
    id: 1,
    title: "Navigating HIPAA Certification: The What, Why, and How [Updated 2023]",
    href: "https://teachmehipaa.com/blog/hipaa-certification/",
    description:
      "Steering an entire organization through an external HIPAA certification is no small feat. The process may look a little different depending on which auditor you partner with. But let's map out the prototypical elements that will be evaluated to understand the scope of the undertaking.",
    cloudinary: "blog/hipaa-compliance",
    alt: "document with HIPAA compliance written on it",
    date: "Jun 22, 2023",
    datetime: "2023-06-22",
    category: { title: "Compliance", href: "https://teachmehipaa.com/blog/tag/compliance/" },
  },
  {
    id: 2,
    title: "How the SLAM Method Can Save Your Organization From Enormous HIPAA Costs",
    href: "https://teachmehipaa.com/blog/the-slam-method/",
    description:
      "An overview of the SLAM method. SLAM method stands for: Sender, Links, Attachments, and Message. By addressing key aspects of all electronic communication, the SLAM acronym can greatly enhance an organization's security posture and compliance efforts.",
    date: "Jul 18, 2023",
    datetime: "2023-07-18",
    alt: "woman on a computer happy",
    category: { title: "Training", href: "https://teachmehipaa.com/blog/tag/training/" },
    cloudinary: "blog/cost-savings",
  },
  {
    id: 3,
    title: "What is a Business Associate Agreement (BAA)? And Why Should You Care?",
    href: "https://teachmehipaa.com/blog/what-is-a-baa/",
    alt: "document with BAA written on it",
    description:
      "By the end of this journey, you'll see why comprehending the BAA is essential in orchestrating a successful HIPAA compliance plan. Let's plunge into the world of BAAs, shall we?",
    date: "Feb 2, 2023",
    datetime: "2023-02-02",
    category: { title: "Contracts", href: "https://teachmehipaa.com/blog/tag/contracts/" },
    cloudinary: "blog/baa",
  },
];
</script>
